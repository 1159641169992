body {
    background-color: #141414;
    font-family: Helvetica, Arial, sans-serif;
    color: white;

}
p {
    /* font-size: larger; */
    font-weight: lighter;
    line-height: 1.4;
}

a {
    color:inherit;
}

#gday {
    float: left;
    width: 400px;
    margin-left: 40px;
    margin-bottom: 10px;

}

#contact-info {
    float: left;
    width: 300px;
    margin-left: 40px;
}

#welcome {
    float: right;
    margin-right: 40px;
}

#josephdaniel {
    font-size: 15.8vw;
    position: absolute;
    top: calc(100vh - 26vw); 
    font-family: 'Raleway', sans-serif;
    /* margin-left: 40px; */
}

#resume {
    margin-top: 25vh;
}


.shortcuts {
    margin-right: 30px;
    margin-top: 30px;
    float: right;
    max-width: 350px;
}



.clearfix::after {
    content: "";
    clear: both;
    display: table;
}


@media only screen and (max-width: 1100px) {
    #welcome {
        margin-top: 100px;
    }

    #gday {
        width: 300px;
    }

    #contact-info {
        width: 300px;
    }

    .shortcuts {
        float: left;
        margin-left: 30px;
    }

}

@media only screen and (max-width: 600px) {

    #josephdaniel {
        --topAdjustment: 130px;
        --topOriginal: calc(100vh - 24.4vw);
        top: calc(var(--topOriginal) - var(--topAdjustment));
    }

    .shortcuts {
        margin-left: 10px;
    }
}

/* 
@media only screen and (max-height: 650px) {

    #josephdaniel {
        position: static;
    }

    
} */